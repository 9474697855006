<template>
    <div>
        <div class="row mt-4">
            <div class="col text-center">
                <div v-if="status == 'settlement'">
                    <img :src="successAsset" alt="" style="height: 60%;">
    
                    <div class="mt-4">
                        <h2>Terima Kasih!</h2>
                        <h5 class="mt-4">Pembayaran Anda Berhasil!</h5>
                    </div>

                    <div class="mt-4">
                        <button @click="goHome()" class="btn text-white btn-block" style="background-color: #00BF99;">Kembali Ke Beranda</button>
                    </div>
                </div>

                <div v-else>
                    <img :src="successAsset" alt="" style="height: 60%;">
    
                    <div class="mt-4">
                        <h2>Terima Kasih!</h2>
                        <h5 class="mt-4">Pembayaran Anda Sedang Diproses!</h5>
                    </div>
                    
                    <div class="mt-4">
                        <button @click="goHome()" class="btn text-white btn-block" style="background-color: #00BF99;">Kembali Ke Beranda</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import successAsset from '../../assets/success.svg';

export default {
    name: 'PageRedirect',

    data() {
        return {
            successAsset,
            status: this.$route.query.transaction_status,
        }
    },

    methods: {
        goHome() {
            this.$router.replace('/');
        }
    }
    
}
</script>